import React, { Component, useEffect, useState } from "react";

import SimpleNavigation from "./navigation";
import HeaderAccessibility from "./header-accessibility";
import HeaderMain from "./header-main";
import classNames from "classnames";

const Header = ({ socialMediaConfig, headerConfig }) => {
  /**
   * Scroll Behaviour
   */
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isTop, setIsTop] = useState(true);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    setScrollPosition(window.scrollY);
    document.addEventListener("scroll", (e) => {
      setScrollPosition((prev) => {
        prev < window.scrollY
          ? setIsScrollingDown(true)
          : setIsScrollingDown(false);
        return window.scrollY;
      });
    });
  }, []);

  useEffect(() => {
    scrollPosition >= 250 ? setIsTop(false) : setIsTop(true);
    scrollPosition > 0 ? setIsScrolled(true) : setIsScrolled(false);
  }, [scrollPosition]);

  const headerClassNames = classNames({
    "scrolling-down": isScrollingDown,
    "is-top": isTop,
    scrolled: isScrolled,
  });

  return (
    <header id="site-header" className={headerClassNames}>
      <HeaderAccessibility socialMediaConfig={socialMediaConfig} />
      <HeaderMain
        socialMediaConfig={socialMediaConfig}
        newsletterUrl={socialMediaConfig?.fieldLinkNewsletter?.url.path}
        headerConfig={headerConfig}
      />
      <img
        src="/logo-sfvv.svg"
        alt="Logo Dokumentationszentrum Flucht, Vertreibung, Versöhnung"
        className="logo-big"
      />
    </header>
  );
};

export default Header;
