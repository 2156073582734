const encodeUrl = (str) => {
  str = (str + "")
    .toString()
    .toLowerCase()
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    ) // remove emojis
    .replace(/ä/g, "ae")
    .replace(/ö/g, "oe")
    .replace(/ü/g, "ue")
    .replace(/ß/g, "ss")
    .replace(/ş/g, "s") // remove turkish "ş" @todo: check for all special characters since following function does not do all of them. Northern and cyrillic signs need to be adressed as well
    .replace(/ı/g, "i") // remove turkish "ı"
    .replace(/[^a-z0-9\s]/, "") // remove special characters
    .replace(/^\s+|\s+$/g, "") // remove leading whitespace
    .replace(/(\s)+/g, "-"); // space between words become '-'

  return encodeURIComponent(str);
};

export { encodeUrl };
