import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import axios from "axios";
import classNames from "classnames";

import { restHostBackend } from "../../../../../config";
import { states } from "../states";
import { FormattedMessage } from "react-intl";

const uploadFiles = (file, token, addFile) => {
  let uploadfile = {
    name: file.name,
    file: file.file,
  };

  uploadfile = JSON.stringify(uploadfile);

  let fileID = "";

  axios({
    method: "post",
    url: `${restHostBackend}/api/webforms/createfile`,
    data: uploadfile,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": token,
    },
  })
    .then((res) => {
      fileID = res.data;
      console.log(file);
      addFile(fileID, file);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};

const convertToBase64 = (file, token, addFile) => {
  const reader = new FileReader(),
    tempFile = file;

  reader.readAsDataURL(file);
  reader.onload = () => {
    tempFile.file = reader.result;
    uploadFiles(tempFile, token, addFile);
  };
  reader.onerror = (error) => {
    console.log("Error: ", error);
  };
};

const FileField = ({ item, token, language }) => {
  const { values, setFieldValue } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );

  // @todo Reset after submit.
  const [files, setFiles] = useState(null);
  const [fileList, setFileList] = useState([]);

  const addFile = (fileId, file) => {
    setFileList((files) => [...(files || []), fileId]);
    setFiles((files) => [...(files || []), { file, fileId } ]);
  };

  const removeFile = (file) => {
    setFileList((files) => files.filter((f) => f !== file.fileId));
    setFiles((files) => files.filter((f) => f.file.name !== file.file.name));
  };

  useEffect(() => {
    setFieldValue(item.id, fileList);
  }, [fileList]);

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
        "file-input-group": true,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        {item.title}{" "}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      <input
        id={item.id}
        name={item.id}
        type="file"
        accept="application/pdf, image/*, .csv, text/plain, application/msword, .doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        className="form-control"
        required={(!!item.required || required) && !optional && visible}
        disabled={!enabled || disabled}
        multiple
        onChange={(event) => {
          const allowedExt =
            item.fileExtensions !== ""
              ? item.fileExtensions
              : ["pdf", "jpg", "jpeg", "png", "svg", "csv", "txt"];

          if (event.target.files.length > 0) {
            Array.prototype.forEach.call(event.target.files, (file) => {
              const ext = file.name.match(/\.([^.]+)$/)[1];

              if (!allowedExt.includes(ext.toLowerCase())) {
                window.alert(`${file.name} has the wrong Fileformat!`);
              }
              // If filesize is bigger than 64mb
              else if (file.size > 64000000) {
                window.alert(`${file.name} is to big! max Filesize 64MB!`);
              } else {
                convertToBase64(file, token, addFile);
              }
            });
          }
        }}
      />
      {item.fileExtensions != "" && (
        <small className="mt-2">
          <FormattedMessage id="allowed_fileformats" /> {item.fileExtensions}
        </small>
      )}
      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
      <div className="files-list">
        {!!files?.length &&
          files.map((file) => (
            <div key={file.file.name} className="files-list-item d-flex">
              <span >{file.file.name}</span>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  removeFile(file)
                }}
                className="file-delete"
              >
                x
              </button>
            </div>
          ))}
      </div>
      {!!files?.length && (
        <button
          onClick={(e) => {
            e.preventDefault();
            setFiles(null);
            setFileList([]);
          }}
        >
          <FormattedMessage id="remove_files" />
        </button>
      )}
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
      />
    </div>
  );
};

FileField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    flex: PropTypes.number,
    required: PropTypes.object,
    states: PropTypes.array,
    fileExtensions: PropTypes.string,
    multiple: PropTypes.object,
  }),
  token: PropTypes.string,
  language: PropTypes.oneOf(["de", "en"]),
};

export default FileField;
