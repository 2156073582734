import React from "react";
import Image from "../../../image/image";

const GalleryItem = (props) => {
  return (
    <div className="gallery-item">
      <Image
        onClick={props.onClick}
        data={{ ...props.imageData, style: props.imageData.teaserStyle }}
        caption={props.caption}
      />
      {props.showDownloadButton && (
        <a
          className="download-button"
          href={props.imageData.url}
          target="_blank"
          rel="noreferrer"
        >
          <span className="icon icon-arrow-down" />
        </a>
      )}
    </div>
  );
};

export default GalleryItem;
