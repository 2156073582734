import React from "react";
import Image from "../../../image/image";

const SliderItem = ({ item }) => {
  // copy to clipboard function
  const copyCaption = (caption, credit) => {
    let text = "";
    caption && (text += caption);
    caption && credit && (text += "\n")
    credit && (text += credit)

    navigator.clipboard.writeText(text);
  };

  return (
    <article className="slide">
      <Image
        data={{
          ...item.imageData,
          style: item.imageData.sliderStyle,
        }}
      />
      {(item.caption || item.credit) && (
        <div className="caption-wrapper">
          <div className="caption">
            {item.caption && <span>{item.caption}</span>}
            {item.credit && <span> © {item.credit}</span>}
          </div>
          <div className="copy-caption">
            <a
              className="btn btn-primary"
              onClick={() => copyCaption(item.caption, item.credit)}
            >
              Bildnachweis kopieren
            </a>
          </div>
        </div>
      )}
    </article>
  );
};

export default SliderItem;
